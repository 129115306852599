// import React, { Component } from 'react';
// import { useRoutes,useHistory } from "react-router-dom";
// import Themeroutes from "./routes/Router";

// import { Navigate } from "react-router-dom";
// const Login = React.lazy(() => import('./views/pages/login/Login'));
// const Register = React.lazy(() => import('./views/pages/register/Register'));
// const App = () => {

//   const tokenString = localStorage.getItem('token');
  
//   const routing = useRoutes(Themeroutes);
//   const navigate = useHistory();
//   if (!tokenString){ 
//     return <div className="dark">{routing}</div>;
//   }else{
//     return <div className="dark">{routing}</div>;
//   }
  
// };

// export default App;


import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import PrivateRoute from './views/pages/PrivateRoute';




// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Forgotpassword = React.lazy(() => import('./views/pages/forgotpassword/Forgotpassword'));
const Changepassword = React.lazy(() => import('./views/pages/changepassword/Changepassword'));

const Users = React.lazy(() => import("./views/pages/users/Users.js"));
const Dashboard = React.lazy(() => import("./views/Dashboard.js"));
const UsersDetail = React.lazy(() => import('./views/pages/users/UserDetail.js'));
const UserAddEditForm = React.lazy(() => import('./views/pages/users/AddEditForm.js'));
const ContactAdmin = React.lazy(() => import("./views/pages/contact/Contact.js"));
const ContactDetail = React.lazy(() => import('./views/pages/contact/ContactDetail.js'));
const Restaurants = React.lazy(() => import('./views/pages/restaurants/Restaurants.js'));
const RestaurantsDetail = React.lazy(() => import('./views/pages/restaurants/RestaurantDetail.js'));
const RestaurantAddEditForm = React.lazy(() => import('./views/pages/restaurants/AddEditForm.js'));
const Courses = React.lazy(() => import('./views/pages/courses/Courses.js'));
const CourseDetail = React.lazy(() => import('./views/pages/courses/CourseDetail.js'));
const CourseAddEditForm = React.lazy(() => import('./views/pages/courses/AddEditForm.js'));

const CoursesType = React.lazy(() => import('./views/pages/coursestype/Coursestype.js'));
const CoursesTypeDetail = React.lazy(() => import('./views/pages/coursestype/CoursestypeDetail.js'));
const CoursesTypeAddEditForm = React.lazy(() => import('./views/pages/coursestype/AddEditForm.js'));


const EditProfile = React.lazy(() => import('./views/pages/users/EditProfile.js'));
const ResturantList = React.lazy(() => import('./views/pages/front/ResturantList.js'));
const CoursesList = React.lazy(() => import('./views/pages/front/CoursesList.js'));
const CoursesDetail = React.lazy(() => import('./views/pages/front/CoursesDetail.js'));
const QuizDetail = React.lazy(() => import('./views/pages/front/QuizDetail.js'));

const EnrollList = React.lazy(() => import('./views/pages/front/EnrollList.js'));
const UserEditProfile = React.lazy(() => import('./views/pages/front/UserEditProfile.js'));

const QuizList = React.lazy(() => import('./views/pages/quiz/Quiz.js'));
const QuizForm = React.lazy(() => import('./views/pages/quiz/AddEditForm.js'));
const UserQuizList = React.lazy(() => import('./views/pages/quiz/UserQuizList.js'));



const About = React.lazy(() => import("./views/About.js"));
// const Alerts = React.lazy(() => import("./views/ui/Alerts.js"));
// const Badges = React.lazy(() => import("./views/ui/Badges.js"));
// const Buttons = React.lazy(() => import("./views/ui/Buttons.js"));
// const Cards = React.lazy(() => import("./views/ui/Cards.js"));
// const Grid = React.lazy(() => import("./views/ui/Grid.js"));
// const Tables = React.lazy(() => import("./views/ui/Tables.js"));
// const Forms = React.lazy(() => import("./views/ui/Forms.js"));
// const Breadcrumbs = React.lazy(() => import("./views/ui/Breadcrumbs.js"));
const Contact = React.lazy(() => import('./views/pages/front/Contact'));
const Home = React.lazy(() => import('./views/pages/front/Home'));


const App = () => {

  return ( 
    
    <HashRouter>
      <Switch>
        <Route exact path="/contact" name="Contact" component={Contact} /> 
        <Route exact path="/" name="Home" component={Home} />
        <Route exact path="/login" name="Login Page" component={Login} />
        <Route exact path="/register" name="Register Page" component={Register} />  
        <Route exact path="/forgotpassword" name="Forgot Password" component={Forgotpassword} />
        <Route exact path="/reset-password" name="Reset Password" component={Changepassword} />  
        <Route exact path="/about" name="About Page" component={About} /> 
        <Route exact path="/restaurant-list" name="Restaurant Page" component={ResturantList} /> 
        <Route exact path="/courses-list/:id" name="Course Page" component={CoursesList} /> 
        <PrivateRoute exact path="/courses-detail/:id" name="Course Detail" component={CoursesDetail} /> 
        <PrivateRoute exact path="/quiz-detail/:id" name="Quiz Detail" component={QuizDetail} /> 
        
        <PrivateRoute exact path="/enroll-list" name="Enroll Page" component={EnrollList} /> 
        <PrivateRoute exact path="/user-edit-profile" name="User Edit Page" component={UserEditProfile} /> 
        <PrivateRoute exact path="/dashboard" name="Dashboard Page" component={Dashboard} /> 
        <PrivateRoute exact path="/users" name="User Page" component={Users} /> 
        <PrivateRoute exact path="/user/usersdetail/:id" name="User Page" component={UsersDetail} /> 
        <PrivateRoute exact path="/user/useradd" name="User Page" component={UserAddEditForm} /> 
        <PrivateRoute exact path="/user/useredit/:id" name="User Page" component={UserAddEditForm} />

        <PrivateRoute exact path="/contactadmin" name="Contact Page" component={ContactAdmin} /> 
        <PrivateRoute exact path="/contact/contactdetail/:id" name="Contact Page" component={ContactDetail} /> 
        
        <PrivateRoute exact path="/quiz/addquiz" name="Quiz Page" component={QuizForm} />
        <PrivateRoute exact path="/quiz/editquiz/:id" name="Quiz Page" component={QuizForm} />
        <PrivateRoute exact path="/quiz/quizdelete/:id" name="Quiz Page" component={QuizForm} />
        <PrivateRoute exact path="/quiz/:id" name="Quiz Page" component={QuizList} />
        <PrivateRoute exact path="/quiz/user-complated-quiz/:id/:userid" name="User Quiz Page" component={UserQuizList} />
        

        <PrivateRoute exact path="/restaurants" name="Restaurant Page" component={Restaurants} /> 
        <PrivateRoute exact path="/restaurants/restaurantsdetail/:id" name="Restaurant Page" component={RestaurantsDetail} /> 
        <PrivateRoute exact path="/restaurants/restaurantsadd" name="Restaurant Page" component={RestaurantAddEditForm} /> 
        <PrivateRoute exact path="/restaurants/restaurantsedit/:id" name="Restaurant Page" component={RestaurantAddEditForm} /> 
        <PrivateRoute exact path="/courses" name="Courses Page" component={Courses} /> 
        <PrivateRoute exact path="/courses/coursesdetail/:id" name="Courses Page" component={CourseDetail} /> 
        <PrivateRoute exact path="/courses/coursesadd" name="Courses Page" component={CourseAddEditForm} /> 
        <PrivateRoute exact path="/courses/coursesedit/:id" name="Courses Page" component={CourseAddEditForm} /> 
        <PrivateRoute exact path="/courses-type" name="Courses Type Page" component={CoursesType} /> 
        <PrivateRoute exact path="/courses-type/courses-type-detail/:id" name="Courses Type Page" component={CoursesTypeDetail} /> 
        <PrivateRoute exact path="/courses-type/courses-type-add" name="Courses Type Page" component={CoursesTypeAddEditForm} /> 
        <PrivateRoute exact path="/courses-type/courses-type-edit/:id" name="Courses Type Page" component={CoursesTypeAddEditForm} /> 

        <PrivateRoute exact path="/profile" name="Profile" component={EditProfile} /> 
      </Switch>  
    </HashRouter>   
   
     
  );
  
    
  
 
}

export default App;


